/**Created by liaoyingchao on 11/23/22.*/

<template>
  <div class="login" v-loading="pageLoading">
    <div class="header-div">
      <div class="logo">
        <img src="../assets/logo.png">
      </div>
      <div class="web-title">运营管理平台</div>
    </div>
    <div class="bg-img">
      <img src="../assets/bg3.jpg">
      <div class="login-img">
        <img src="../assets/login-img.png">
      </div>
    </div>
    <div class="login-div">
      <div class="login-title">
        <div class="text">欢 迎 登 录</div>
        <div class="text-en">Welcome to login!</div>
      </div>
      <div class="login-item">
        <el-input v-model="userName" size="large" placeholder="请输入用户名">
          <template #prepend>
            <el-icon>
              <User/>
            </el-icon>
          </template>
        </el-input>
      </div>
      <div class="login-item">
        <el-input v-model="password" size="large" type="password" placeholder="请输入密码" show-password>
          <template #prepend>
            <el-icon>
              <Lock/>
            </el-icon>
          </template>
        </el-input>
      </div>
      <div class="login-item">
        <el-input v-model="code" size="large" placeholder="请输入短信验证码">
          <template #prepend>
            <el-icon>
              <Message/>
            </el-icon>
          </template>
          <template #append>
            <el-button size="large" @click="getLoginCode" :disabled="codeTime > 0">{{ codeTime ? (codeTime +"秒重新获取") : "获取验证码" }}</el-button>
          </template>
        </el-input>
      </div>
      <div class="login-item">
        <el-button size="large" type="primary" style="width: 100%;margin-top: 10px;" @click="login">登 录</el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {ref} from 'vue'
  import {User, Lock, Message} from '@element-plus/icons-vue'
  import userApi from '@/api/user'
  import {ElMessage} from 'element-plus';
  import md5String from '@/utils/md5.js'
  import token from "@/utils/token";
  import {useRouter} from 'vue-router'

  const router = useRouter();

  const userName = ref('')
  const password = ref('')
  const code = ref('')
  const codeTime = ref(0)
  const pageLoading = ref(false)


  function login() {
    if (!userName.value) {
      ElMessage.error('请输入用户名！')
      return ;
    } else if (!password.value) {
      ElMessage.error('请输入密码！')
      return ;
    } else if (!code.value) {
      ElMessage.error('请输入短信验证码！')
      return ;
    }
    pageLoading.value = true
    userApi.login({
      userUsername: userName.value,
      code: code.value,
      userPassword: md5String(password.value)
    }).then(res => {
      if (res.code == '0') {
        let tokenStr = res.data.token || ''
        token.saveToken(tokenStr)
        router.push({
          path: '/home'
        })
      } else {
        ElMessage.error(res.errInfo || res.msg || '请求接口失败！');
      }
    }).finally(() => {
      pageLoading.value = false
    })
  }

  function getLoginCode() {
    if (!userName.value) {
      ElMessage.error('请输入用户名！')
      return ;
    }
    pageLoading.value = true
    userApi.getLoginCode({
      userUsername: userName.value
    }).then(res => {
      if (res.code == '0') {
        codeTime.value = 60;
        let interval = setInterval(() => {
          codeTime.value --
          if (codeTime.value <= 0) {
            clearInterval(interval);
          }
        }, 1000)
        ElMessage.success("获取验证码成功！请及时查看！");
      } else {
        ElMessage.error(res.msg || '请求接口失败！');
      }
    }).finally(() => {
      pageLoading.value = false
    })
  }

</script>

<style lang="stylus" scoped type="text/stylus">
  .login {
    position relative;
    width 100%;
    height 100%;

    .header-div {
      height 60px;
      display flex;
      align-items center;

      .logo {
        width 167px;
        padding 8px 10px 6px;

        img {
          display block;
          height 46px;
        }
      }

      .web-title {
        padding-top 14px;
        font-size 22px;
      }
    }

    .bg-img {
      position relative;
      height calc(100% - 60px);
      overflow hidden;
      text-align center;
      font-size 0;

      img {
        height 100%;
      }

      .login-img {
        position absolute;
        left 10%;
        top 20%;
        height 60%;

        img {
          opacity 1;
        }
      }
    }

    .login-div {
      position absolute;
      right 10%;
      top 50%;
      transform translateY(-50%)
      background-color white;
      width 35%;
      min-width 350px;
      max-width 500px;
      border-radius 3px;
      padding 30px 40px 50px;

      .login-title {
        text-align center;
        font-size 24px;
        padding 10px 30px;

        .text {
          font-size 26px;
          color #333;
        }

        .text-en {
          margin-top 5px;
          font-size 14px;
          color #999;
        }
      }

      .login-item {
        margin-top 26px;
      }
    }
  }
</style>